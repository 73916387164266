
import { computed } from '@vue/runtime-core'
import { Options, Vue, setup } from 'vue-class-component';
import { useMeta } from 'vue-meta';

import HeroComponent from './sections/Hero.vue';
import Services from './sections/Services.vue';
import Portfolio from './sections/Portfolio.vue';
import Products from './sections/Products.vue';
import Contact from './sections/Contact.vue';
@Options({
  components: {
    HeroComponent,
    Services,
    Portfolio,
    Products,
    Contact
  }
})
export default class HomeView extends Vue {
  meta = setup(() => useMeta(
    computed(() => ({
        title: 'Bobinados de Motores, Instalaciones Eléctricas y de portones - Silverwolf motores',
        description: 'Ofrecemos servicios profesionales de bobinados de motores, instalaciones eléctricas y de portones eléctricos para empresas y clientes residenciales. Contamos con técnicos altamente capacitados y utilizamos materiales de alta calidad para garantizar la eficiencia y seguridad en todos nuestros trabajos. Contactanos hoy para una cotización gratuita.',
        og: {
          title: "Bobinados de Motores, Instalaciones Eléctricas y de portones - Silverwolf motores",
          type: "website",
          url: "https://swmotores.com"
        },
        twitter: {
          card: "summary",
          site: "https://swmotores.com",
          title: "Bobinados de Motores, Instalaciones Eléctricas y de portones - Silverwolf motores",
          description: "Ofrecemos servicios profesionales de bobinados de motores, instalaciones eléctricas y de portones eléctricos para empresas y clientes residenciales. Contamos con técnicos altamente capacitados y utilizamos materiales de alta calidad para garantizar la eficiencia y seguridad en todos nuestros trabajos. Contactanos hoy para una cotización gratuita."
        }
      }))
  ))
}
