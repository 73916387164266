import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["button", {'disable':_ctx.disable}]),
    target: _ctx.link,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToContent($event)))
  }, _toDisplayString(_ctx.text), 11, _hoisted_1))
}