
import { Vue, Options } from 'vue-class-component';
import ProductsCarousel from '@/components/ProductsCarousel.vue';
@Options({
    components: {
        ProductsCarousel
    }
})
export default class Products extends Vue {

}
