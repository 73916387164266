
import { isMethod } from '@babel/types';
import { Vue, Options } from 'vue-class-component';
    @Options({
            props:{
                text:'',
                disable:false,
                link:''
            }
    })
    export default class Btn extends Vue {
        text!:string
        disable?:boolean
        link?:string
        scrollToContent!: typeof isMethod
    }
