import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7e95eba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hambutton = _resolveComponent("Hambutton")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: require('../assets/images/'+_ctx.$tm('header.dsk_logo')),
            alt: "Silverwolf desktop logo",
            class: "dsk"
          }, null, 8, _hoisted_3),
          _createElementVNode("img", {
            src: require('../assets/images/'+_ctx.$tm('header.mbl_logo')),
            alt: "Silverwolf desktop logo",
            class: "mbl"
          }, null, 8, _hoisted_4)
        ]),
        _createVNode(_component_Hambutton, {
          isActive: _ctx.isActive,
          onClick: _cache[0] || (_cache[0] = ($event: any) => {{ _ctx.isActive = !_ctx.isActive;}}),
          class: "responsive_button"
        }, null, 8, ["isActive"]),
        _createElementVNode("ul", {
          class: _normalizeClass({ open: _ctx.isActive })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('header.items'), (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.text,
              onClick: _cache[2] || (_cache[2] = ($event: any) => {{ _ctx.isActive = !_ctx.isActive;}})
            }, [
              _createElementVNode("a", {
                href: "#",
                target: item.link,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollToContent($event)))
              }, _toDisplayString(item.text), 9, _hoisted_5)
            ]))
          }), 128))
        ], 2)
      ])
    ])
  ]))
}