
    import { Vue, Options } from 'vue-class-component';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';

    @Options({
        components:{
            Swiper,
            SwiperSlide
        }
    })
    export default class ProductsCarousel extends Vue {
        products:Array<any> =[]
        mounted():void{
            this.axios.get('https://api.silverwolfstore.uy/api/getNewArrival/').then((res)=>{
                this.products = res.data.data
            })
        }
    }
