import { Locales } from "./locales";

import header from '@/data/es/components/header.json'
import hero from '@/data/es/components/hero.json'

const es = {
  header: header,
  hero:hero
}
export const messages = {
  [Locales.ES]: es,

};

export const defaultLocale = Locales.ES;