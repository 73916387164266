import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/home/HomeView.vue'
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
  history: createWebHistory()
})


export default router
