import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34192702"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "products__carousel__card__image-wrapper" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "products__carousel__card__details" }
const _hoisted_4 = { class: "products__carousel__card__details__category" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "products__carousel__card__details__price" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createBlock(_component_Swiper, {
    class: "products__carousel",
    "slides-per-view": 2,
    "space-between": 10
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (item) => {
        return (_openBlock(), _createBlock(_component_SwiperSlide, {
          class: "products__carousel__card",
          key: item._id
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("a", {
                class: "products__carousel__card__image-wrapper__image",
                style: _normalizeStyle({'background-image': 'url(' + 'https://api.silverwolfstore.uy/api/getUrlImage/'+item.portada + ')'}),
                href: 'https://silverwolfstore.uy/shop/product/'+item.slug,
                target: "_blank",
                loading: "lazy"
              }, null, 12, _hoisted_2)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("a", {
                  href: 'https://silverwolfstore.uy/shop/categorias/' + item.categoria.toLowerCase(),
                  target: "_blank"
                }, _toDisplayString(item.categoria), 9, _hoisted_5)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h6", null, [
                  _createElementVNode("a", {
                    href: 'https://silverwolfstore.uy/shop/product/'+item.slug,
                    target: "_blank"
                  }, _toDisplayString(item.titulo), 9, _hoisted_7)
                ]),
                _createElementVNode("h3", null, "$" + _toDisplayString(item.precio), 1)
              ])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}