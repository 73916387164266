
    import { Vue, Options } from 'vue-class-component';
    import Button from '../../../components/Button.vue';
    @Options({
        components:{
            Button
        }
    })
    export default class HeroComponent extends Vue{

    }
