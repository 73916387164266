import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_Header = _resolveComponent("Header")!
  const _component_BackLines = _resolveComponent("BackLines")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Header),
    _createVNode(_component_BackLines),
    _createVNode(_component_router_view)
  ], 64))
}