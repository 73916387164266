
import { isMethod } from '@babel/types';
import { Vue,Options } from 'vue-class-component';

import { LOCALES } from "@/i18n/locales";
import { defaultLocale } from "@/i18n";

import Hambutton from './Hambutton.vue';
import { Locale } from 'vue-i18n';
@Options({
    components:{
        Hambutton
    },
    data: () => ({ LOCALES, defaultLocale })
})
export default class Header extends Vue {
    isActive = false
    observer!: typeof IntersectionObserver
    LOCALES!: Locale
    scrollToContent!: typeof isMethod
    defaultLocale!: Locale
    mounted():void{
        this.obsHeader()
        // this.createObserver()
    }
    created(){
        // setTimeout(()=>{
        //     // this.createObserver()
        // },2000)
    }
    obsHeader(){
        var obs = new IntersectionObserver(this.toggleShadow, {
            threshold: 0,
            rootMargin: '0px 0px -99.9% 0px'
        }) 
        obs.observe(this.$el)
    }
    toggleShadow(entries: { target: Element; isIntersecting: boolean; }[]) {
            entries.forEach(({ target, isIntersecting }) => {
             isIntersecting ? target.classList.add('shadow') : target.classList.remove('shadow') 
            })
    }

    createObserver(){
        console.log(this.$el)
        var observer = new IntersectionObserver(this.onElementObserved, {
            threshold: 0,
            rootMargin: '0px 0px -85% 0px'
        })
        const sectionData = document.querySelectorAll('.section')
        sectionData.forEach((el:any)=>{
            console.log(el)
                observer.observe(el)
            })
    }
    onElementObserved(entries: { target: any; isIntersecting: any; }[]) {
            entries.forEach(({ target, isIntersecting }) => {
                const id = target.getAttribute('id')
                if (isIntersecting) {
                    let target = this.$el.querySelector(`ul li a[target="#${id}"]`)
                    let active = this.$el.querySelector(`ul li a.active`)  
                    active ? active.classList.remove('active') : false
                    target ? target.classList.add('active') : false
                }
            })
    }
}
