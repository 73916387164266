import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroComponent = _resolveComponent("HeroComponent")!
  const _component_Services = _resolveComponent("Services")!
  const _component_Portfolio = _resolveComponent("Portfolio")!
  const _component_Products = _resolveComponent("Products")!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeroComponent, { class: "section" }),
    _createVNode(_component_Services, { class: "section" }),
    _createVNode(_component_Portfolio, { class: "section" }),
    _createVNode(_component_Products, { class: "section" }),
    _createVNode(_component_Contact, { class: "section" })
  ], 64))
}