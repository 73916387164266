
import { Vue,Options } from 'vue-class-component';
@Options({
    props: {
    isActive:false
  }
})
export default class Hambutton extends Vue {
    isActive!: boolean;
}   
