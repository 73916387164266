import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager } from 'vue-meta'
import { messages, defaultLocale } from "@/i18n";
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'

import "./styles/app.scss";

//Create
const i18n =  createI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});

const mixin ={
      methods:{
        scrollToContent(el:any) {
             el.preventDefault()
            const target = el.target.getAttribute("target")
            const container =document.querySelector(target)
            window.scrollTo(0,container.offsetTop-80)
          }
        }
}


const app = createApp(App)
app.use(VueAxios, axios)
app.use(i18n)
app.mixin(mixin)
app.use(createMetaManager())
app.use(router)
app.mount('#app')
