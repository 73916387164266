
  import Header from './components/Header.vue';
  import BackLines from '@/components/BackLines.vue'

  export default{
    components:{
      Header,
      BackLines
    }
  }
